import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import { URL } from "../../../services/API";
import { POST, PUT } from "../../../services/API/AxiosRequests";

export default function CreateUserForm({
  open,
  setOpen,
  individualUser,
  setIndividualUser,
  setUserUpdated,
}: any) {
  const [validateEmail, setValidateEmail] = useState<any>(true);

  const [loader, setLoader] = useState(false);

  const access_token = useSelector((state: any) => state.user.access_token);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    setLoader(true);
    const target = e.target as any;

    e.preventDefault();

    try {
      const payload = {
        name: target.name?.value,
        email: target.email?.value,
        password: target.password?.value,
        adminCreatedUser: true,
        isValid: true,
      };

      !!individualUser?._id
        ? await PUT(
            `${URL.EDIT_USER}/${individualUser._id}`,
            access_token,
            payload
          )
        : await POST(URL.REGISTER, payload);

      setIndividualUser({
        createdAt: "",
        email: "",
        name: "",
        stripeCustomerID: "",
        subscriptionID: "",
        __v: -1,
        _id: "",
      });

      setUserUpdated(true);

      setOpen(false);
      return;
    } catch (error: any) {
      const errorMessage = error?.response?.data?.message;

      console.error("error", errorMessage);
    } finally {
      setLoader(false);
    }
  };

  return (
    <Paper>
      <Dialog
        open={open}
        onClose={() => {
          setIndividualUser({
            createdAt: "",
            email: "",
            name: "",
            stripeCustomerID: "",
            subscriptionID: "",
            __v: -1,
            _id: "",
          });
          setOpen(false);
        }}
        aria-labelledby="Add New User"
      >
        <form onSubmit={handleSubmit}>
          <DialogTitle id="create-new-user">
            {individualUser?._id === "" ? "Add New User" : "Edit User"}
          </DialogTitle>
          <DialogContent>
            <TextField
              margin="dense"
              id="email"
              disabled={!!individualUser?._id}
              label="Email Address"
              type="email"
              fullWidth
              name="email"
              required={true}
              value={individualUser?.email || ""}
              error={validateEmail ? false : true}
              helperText={
                validateEmail ? null : "Please enter a valid email address"
              }
              onChange={(e) => {
                setValidateEmail(
                  /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
                    e.target.value
                  )
                );
                setIndividualUser({
                  ...individualUser,
                  email: e.target.value,
                });
              }}
            />

            <TextField
              margin="dense"
              id="name"
              label="Name"
              name="name"
              inputProps={{ minLength: 3 }}
              type="text"
              fullWidth
              required
              value={individualUser?.name || ""}
              onChange={(e) =>
                setIndividualUser({
                  ...individualUser,
                  name: e.target.value,
                })
              }
            />

            <TextField
              margin="dense"
              id="password"
              name="password"
              label="New Password"
              placeholder="Enter New Password"
              inputProps={{ minLength: 8 }}
              type="password"
              fullWidth
              required
            />
          </DialogContent>
          <DialogActions sx={{ mb: "10px", mr: "15px" }}>
            <Button
              type="button"
              color="primary"
              variant="outlined"
              onClick={() => {
                setIndividualUser({
                  createdAt: "",
                  email: "",
                  name: "",
                  stripeCustomerID: "",
                  subscriptionID: "",
                  __v: -1,
                  _id: "",
                });
                setOpen(false);
              }}
            >
              Cancel
            </Button>
            <LoadingButton
              type="submit"
              color="primary"
              variant="outlined"
              loading={loader ? true : false}
            >
              {individualUser?._id === "" ? "Create" : "Save Changes"}
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </Paper>
  );
}

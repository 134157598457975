import { useEffect, useState } from "react";

import {
  Box,
  Button,
  CircularProgress,
  TextField,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
import { URL } from "../services/API";
import { GET, PUT } from "../services/API/AxiosRequests";

const RephraserForm = () => {
  const [submitLoader, setSubmitLoader] = useState(false);

  const access_token = useSelector((state: any) => state.user.access_token);

  const [rephraser, setRephraser] = useState<any>(null);

  const getRephraser = async () => {
    try {
      const rephraserDetails = await GET(
        `${URL.GET_REPHRASER_DETAILS}`,
        access_token
      );
      setRephraser(rephraserDetails[0]);
    } catch (error) {
      console.error({ error });
    }
  };

  const handleSubmit = async (e: any) => {
    setSubmitLoader(true);

    e.preventDefault();

    const target = e.target;

    if (
      !target.basePrompt.value ||
      !target.temp.value ||
      !target.maxTokens.value
    )
      return;

    try {
      const payload = {
        basePrompt: target.basePrompt.value,
        slug: "rephraser-tool",
        temperature: Number(target.temp.value),
        maxTokens: Number(target.maxTokens.value),
      };

      const updated = await PUT(`${URL.EDIT_REPHRASER}`, access_token, payload);

      setRephraser(updated);

      alert("Rephraser Details Updated Successfully");
    } catch (error: any) {
      const errorMessage = error.message;

      console.error("error", errorMessage);
    } finally {
      setSubmitLoader(false);
    }
  };

  useEffect(() => {
    if (!rephraser) {
      getRephraser();
    }
  }, [rephraser]);

  return (
    <>
      <Helmet title="Rephraser Details" />

      <Box
        sx={{
          width: ["100%", "50%", "50%", "40%"],
          margin: ["20px auto", "50px auto"],
        }}
      >
        <Typography variant="h3">Rephraser Details</Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            label="Max Tokens"
            name="maxTokens"
            placeholder="Enter Max Tokens here"
            variant="outlined"
            fullWidth
            margin="normal"
            type="number"
            required
            value={rephraser?.maxTokens || ""}
            onChange={(e) =>
              setRephraser({
                ...rephraser,
                maxTokens: e.target.value,
              })
            }
          />

          <TextField
            margin="normal"
            fullWidth
            label="Temperature"
            name="temp"
            placeholder="Enter Tool Temperature here"
            variant="outlined"
            required
            value={rephraser?.temperature.toString() || ""}
            onChange={(e) =>
              setRephraser({
                ...rephraser,
                temperature: e.target.value,
              })
            }
          />

          <TextareaAutosize
            style={{
              width: "100%",
              height: "100%",
              padding: "10px",
              resize: "none",
              outline: "none",
              border: "1px solid #ccc",
              borderRadius: "5px",
              fontFamily: "sans-serif",
              fontSize: "14px",
            }}
            minRows={30}
            name="basePrompt"
            placeholder="Enter tool base prompt here"
            required
            value={rephraser?.basePrompt}
            onChange={(e) =>
              setRephraser({
                ...rephraser,
                basePrompt: e.target.value,
              })
            }
          />

          <Box sx={{ textAlign: "right", mt: 2 }}>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              sx={{ minWidth: "120px", minHeight: "35px" }}
            >
              {submitLoader ? (
                <CircularProgress size={20} sx={{ ml: 2 }} />
              ) : (
                "Submit"
              )}
            </Button>
          </Box>
        </form>
      </Box>
    </>
  );
};

export default RephraserForm;

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Paper,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";
import { URL } from "../services/API";
import { DELETE } from "../services/API/AxiosRequests";
import {
  Edit as EditIcon,
  Cancel as CancelOutlinedIcon,
} from "@mui/icons-material";

export default function ConfirmTemplateDelete({
  open,
  setOpen,
  deletionId,
  setLoading,
}: any) {
  const access_token = useSelector((state: any) => state.user.access_token);

  const onDelete = async () => {
    // bearer-token will send in delete api instead of empty string when admin is authentic
    try {
      const deleteTempalte = await DELETE(
        `${URL.DELETE_TEMPLATE}/${deletionId}`,
        access_token
      );
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Paper>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="Create New User"
      >
        <DialogContent sx={{ textAlign: "center" }}>
          <CancelOutlinedIcon color="error" sx={{ fontSize: "2rem", my: 2 }} />
          <Typography variant="h4" my={2}>
            Are your sure?
          </Typography>
          <Typography>
            Do you really want to delete this template ? This process cannot be
            undone.
          </Typography>
        </DialogContent>
        <DialogActions sx={{ scale: "95%", mb: 1 }}>
          <Button
            type="button"
            color="error"
            variant="contained"
            onClick={() => setOpen(false)}
          >
            Cancel
          </Button>
          <Button
            type="button"
            color="primary"
            variant="contained"
            onClick={() => {
              onDelete();
              setOpen(false);
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
}

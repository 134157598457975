import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";

import {
  Alert as MuiAlert,
  Checkbox,
  FormControlLabel,
  Button,
  TextField as MuiTextField,
  CircularProgress,
} from "@mui/material";
import { spacing } from "@mui/system";

import useAuth from "../../hooks/useAuth";
import { POST } from "../../services/API/AxiosRequests";
import { URL } from "../../services/API";
import { useDispatch } from "react-redux";
import { setUser } from "../../redux/slices/userSlice";

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

function SignIn() {
  const navigate = useNavigate();
  const { signIn } = useAuth();
  const dispatch = useDispatch();

  const [loader, setLoader] = React.useState(false);

  return (
    <Formik
      initialValues={{
        email: "admin@admin.com",
        password: "admin123",
        submit: false,
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email("Must be a valid email")
          .max(255)
          .required("Email is required"),
        password: Yup.string().max(255).required("Password is required"),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          setLoader(true);
          const payload = {
            email: values.email,
            password: values.password,
          };

          signIn(values.email, values.password);

          // await signIn(values.email, values.password);
          const { access_token, user } = await POST(URL.LOGIN, payload);

          if (!!access_token) {
            dispatch(
              setUser({
                access_token: access_token,
                user: user,
              })
            );
          }

          setLoader(false);
          navigate("/dashboard/users");
        } catch (error: any) {
          const message = error.message || "Something went wrong";

          setStatus({ success: false });

          setErrors({ submit: message });

          setLoader(false);

          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          {errors.submit && (
            <Alert mt={2} mb={3} severity="warning">
              {errors.submit}
            </Alert>
          )}
          <TextField
            type="email"
            name="email"
            label="Email Address"
            value={values.email}
            error={Boolean(touched.email && errors.email)}
            fullWidth
            helperText={touched.email && errors.email}
            onBlur={handleBlur}
            onChange={handleChange}
            my={2}
          />
          <TextField
            type="password"
            name="password"
            label="Password"
            value={values.password}
            error={Boolean(touched.password && errors.password)}
            fullWidth
            helperText={touched.password && errors.password}
            onBlur={handleBlur}
            onChange={handleChange}
            my={2}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isSubmitting}
          >
            {loader ? (
              <CircularProgress size={24} sx={{ color: "white" }} />
            ) : (
              "Sign in"
            )}
          </Button>
          {/* <Button
            component={Link}
            to="/auth/reset-password"
            fullWidth
            color="primary"
          >
            Forgot password
          </Button> */}
        </form>
      )}
    </Formik>
  );
}

export default SignIn;

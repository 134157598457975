import styled from "@emotion/styled";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  Box,
  CircularProgress,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { spacing } from "@mui/system";
import { useSelector } from "react-redux";
import { URL } from "../services/API";
import { GET } from "../services/API/AxiosRequests";
import Actions from "./Actions";
import ConfirmTemplateDelete from "./ConfirmTemplateDelete";

const Paper = styled(MuiPaper)(spacing);

type RowType = {
  [key: string]: string | number;
  id: string;
  analytics: number;
  createdAt: string;
  heading: string;
};
function descendingComparator(a: RowType, b: RowType, orderBy: string) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order: "desc" | "asc", orderBy: string) {
  return order === "desc"
    ? (a: RowType, b: RowType) => descendingComparator(a, b, orderBy)
    : (a: RowType, b: RowType) => -descendingComparator(a, b, orderBy);
}

function stableSort(
  array: Array<RowType>,
  comparator: (a: RowType, b: RowType) => number
) {
  const stabilizedThis = array.map((el: RowType, index: number) => ({
    el,
    index,
  }));
  stabilizedThis.sort((a, b) => {
    const order = comparator(a.el, b.el);
    if (order !== 0) return order;
    return a.index - b.index;
  });

  return stabilizedThis.map((element) => element.el);
}

type HeadCell = {
  id: string;
  alignment: "left" | "center" | "right" | "justify" | "inherit" | undefined;
  label: string;
  disablePadding?: boolean;
};
const headCells: Array<HeadCell> = [
  { id: "id", alignment: "left", label: "Template ID" },
  { id: "analytics", alignment: "left", label: "Analytics" },
  { id: "createdAt", alignment: "left", label: "CreatedAt" },
  { id: "action", alignment: "right", label: "Actions" },
];

type EnhancedTableHeadProps = {
  numSelected: number;
  order: "desc" | "asc";
  orderBy: string;
  rowCount: number;
  onSelectAllClick: (e: any) => void;
  onRequestSort: (e: any, property: string) => void;
};
const EnhancedTableHead: React.FC<EnhancedTableHeadProps> = (props) => {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property: string) => (event: any) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead sx={{ bgcolor: "#132351" }}>
      <TableRow>
        <TableCell padding="checkbox">
          {/* <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
          /> */}
        </TableCell>
        {headCells.map((headCell: HeadCell) => (
          <TableCell
            sx={{ color: "#ffffff" }}
            key={headCell.id}
            // align={headCell.alignment}
            padding={headCell.disablePadding ? "none" : "normal"}
            // sortDirection={orderBy === headCell.id ? order : false}
          >
            {/* <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            > */}
            {headCell.label}
            {/* </TableSortLabel> */}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default function EnhancedTemplateTable({
  rows,
  setDeletionId,
  deletionId,
  setLoading,
  loading,
  setOpen,
  open,
}: any) {
  const [templates, setTemplates] = useState<
    {
      templates: [];
      currentPage: number;
      pages: number;
      noOfTemplates: number;
    }[]
  >(rows.templates);

  const [order, setOrder] = useState<"desc" | "asc">("asc");

  // const [templates, setTemplates] = useState<Array<RowType>>(rows.templates);

  const [orderBy, setOrderBy] = useState("customer");

  const [page, setPage] = useState(0);

  const [rowsPerPage, setRowsPerPage] = useState(10);

  // const [open, setOpen] = useState(false);

  const [loader, setLoader] = useState(false);

  const navigate = useNavigate();

  const [selected, setSelected] = useState<Array<string>>([]);

  const access_token = useSelector((state: any) => state.user.access_token);

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelectedIds = rows.map((n: any) => n.heading);
      setSelected(newSelectedIds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (
    event: React.MouseEvent<HTMLTableCellElement, MouseEvent>,
    name: string
  ) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: Array<string> = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleRequestSort = (event: any, property: string) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = async (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    try {
      const { templates } = await GET(
        `${URL.GET_TEMPLATES}?pageNumber=${newPage + 1}&limit=${rowsPerPage}`,
        access_token
      );

      setTemplates(templates);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
      setLoader(false);
    }
    setPage(newPage);
  };

  const handleChangeRowsPerPage = async (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    let limit = event.target.value;
    let current_page = 0;
    try {
      const { templates } = await GET(
        `${URL.GET_TEMPLATES}?pageNumber=${current_page + 1}&limit=${limit}`,
        access_token
      );

      setTemplates(templates);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
      setLoader(false);
    }
    setRowsPerPage(parseInt(event.target.value, 10));
    current_page = 0;
    setPage(0);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows?.length - page * rowsPerPage);

  const handleDelete = (id: string) => {
    setDeletionId(id);

    setOpen(true);
  };

  const formatDate = (isoDate: string) => {
    // Convert ISO date to JavaScript date object
    const date = new Date(isoDate);

    // Extract day, month, and year from the date object
    const day = String(date.getUTCDate()).padStart(2, "0");
    const month = String(date.getUTCMonth() + 1).padStart(2, "0");
    const year = String(date.getUTCFullYear());

    // Format the date in "DD-MM-YYYY" format
    return `${day}-${month}-${year}`;
  };

  const handleEdit = (id?: any) => {
    navigate(`/template/edit/${id}`);
  };

  useEffect(() => {
    setTemplates(rows.templates);
  }, [rows.templates]);

  return (
    <div>
      <Paper>
        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "50vh",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Paper>
            {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
            <TableContainer>
              <Table aria-labelledby="tableTitle" aria-label="enhanced table">
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                />
                <TableBody>
                  {/* {stableSort(
                    templates ? templates : [],
                    getComparator(order, orderBy)
                  )
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) */}
                  {templates.map((template: any, index: number) => {
                    const isItemSelected = isSelected(template.heading);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={template.heading}
                        selected={isItemSelected}
                      >
                        <TableCell
                          padding="checkbox"
                          onClick={(event) =>
                            handleClick(event, template.heading)
                          }
                        >
                          {/* <Checkbox
                              checked={isItemSelected}
                              inputProps={{ "aria-labelledby": labelId }}
                            /> */}
                        </TableCell>
                        <TableCell align="left">{template.heading}</TableCell>
                        <TableCell align="left">{template.count}</TableCell>
                        <TableCell align="left">
                          {formatDate(template.createdAt)}
                        </TableCell>
                        <TableCell align="right">
                          <Actions
                            id={template._id}
                            handleEdit={handleEdit}
                            handleDelete={handleDelete}
                            viewUser={false}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 10 * emptyRows }}>
                      <TableCell colSpan={8} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={rows.noOfTemplates || 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        )}
      </Paper>
      <ConfirmTemplateDelete
        open={open}
        setOpen={setOpen}
        deletionId={deletionId}
        setLoading={setLoading}
      />
    </div>
  );
}

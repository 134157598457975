import styled from "@emotion/styled";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  CircularProgress,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";

import { spacing } from "@mui/system";

import Actions from "../../../components/Actions";
import { GET } from "../../../services/API/AxiosRequests";
import { URL } from "../../../services/API";
import { useSelector } from "react-redux";
import { format } from "date-fns";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

type RowType = {
  [key: string]: string | number;
  _id: string;
  name: string;
  email: string;
};

export interface IUser {
  createdAt: string;
  email: string;
  name: string;
  stripeCustomerID: string;
  subscriptionID: string;
  __v: number;
  _id: string;
}

function descendingComparator(a: any, b: any, orderBy: string) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order: "desc" | "asc", orderBy: string) {
  return order === "desc"
    ? (a: any, b: any) => descendingComparator(a, b, orderBy)
    : (a: any, b: any) => -descendingComparator(a, b, orderBy);
}

function stableSort(array: Array<any>, comparator: (a: any, b: any) => number) {
  const stabilizedThis = array?.map((el: any, index: number) => ({
    el,
    index,
  }));
  stabilizedThis.sort((a, b) => {
    const order = comparator(a.el, b.el);
    if (order !== 0) return order;
    return a.index - b.index;
  });

  return stabilizedThis.map((element) => element.el);
}

type HeadCell = {
  id: string;
  alignment: "left" | "center" | "right" | "justify" | "inherit" | undefined;
  label: string;
  disablePadding?: boolean;
};
const headCells: Array<HeadCell> = [
  { id: "name", alignment: "left", label: "Name" },
  { id: "email", alignment: "left", label: "Email" },
  { id: "subscription", alignment: "left", label: "Subscription" },
  { id: "wordUsage", alignment: "left", label: "Word Usage" },
  { id: "createdAt", alignment: "left", label: "Created At" },
  { id: "lastLogin", alignment: "left", label: "Last Login" },
  { id: "action", alignment: "right", label: "Actions" },
];

type EnhancedTableHeadProps = {
  numSelected: number;
  order: "desc" | "asc";
  orderBy: string;
  rowCount: number;
  onSelectAllClick: (e: any) => void;
  onRequestSort: (e: any, property: string) => void;
};
const EnhancedTableHead: React.FC<EnhancedTableHeadProps> = (props) => {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;

  const createSortHandler = (property: string) => (event: any) => {
    property !== "action" && onRequestSort(event, property);
  };
  return (
    <TableHead sx={{ bgcolor: "#132351" }}>
      <TableRow>
        <TableCell padding="checkbox"></TableCell>
        {headCells.map((headCell: HeadCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.alignment}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{ cursor: "default !important" }}
          >
            <TableSortLabel
              active={false}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              hideSortIcon={true}
              sx={{
                cursor: "default !important",
              }}
            >
              <Typography
                sx={{
                  color: orderBy !== headCell?.id ? "#ffffff9a" : "#ffffff",
                  cursor:
                    orderBy !== "action" ? "pointer" : "default !important",
                }}
              >
                {headCell.label}
              </Typography>
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default function UserEnhancedTable({
  rows,
  setOpen,
  setIndividualUser,
  setOpenDeleteDialog,
  setDeletionId,
  deletionId,
  loader,
  setLoader,
  openViewDialog,
  setOpenViewDialog,
  setWordCreditsModal,
}: any) {
  const [userData, setUserData] = useState<
    {
      users: IUser[];
      currentPage: number;
      pages: number;
      noOfUsers: number;
    }[]
  >(rows.users);

  const [order, setOrder] = React.useState<"desc" | "asc">("asc");

  const [orderBy, setOrderBy] = React.useState("name");

  const [page, setPage] = React.useState(0);

  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [selected, setSelected] = React.useState<Array<string>>([]);

  const access_token = useSelector((state: any) => state.user.access_token);

  const Navigate = useNavigate();

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  const handleRequestSort = (event: any, property: string) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = rows.users.map((n: any) => n._id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (
    event: React.MouseEvent<HTMLTableCellElement, MouseEvent>,
    name: string
  ) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: Array<string> = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = async (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    try {
      const { users } = await GET(
        `${URL.GET_ALL_USERS}?pageNumber=${newPage + 1}&limit=${rowsPerPage}`,
        access_token
      );

      setUserData(users);
    } catch (err) {
      console.log(err);
    } finally {
      setLoader(false);
    }
    setPage(newPage);
  };

  const handleChangeRowsPerPage = async (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    let limit = event.target.value;
    let current_page = 0;
    try {
      const { users } = await GET(
        `${URL.GET_ALL_USERS}?pageNumber=${current_page + 1}&limit=${limit}`,
        access_token
      );

      setUserData(users);
    } catch (err) {
      console.log(err);
    } finally {
      setLoader(false);
    }
    setRowsPerPage(parseInt(event.target.value, 10));
    current_page = 0;
    setPage(0);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows?.length - page * rowsPerPage);

  const handleDelete = (id: string) => {
    const userId = userData.filter((user: any) => user._id === id);

    // @ts-ignore
    setDeletionId(userId[0]._id);

    setOpenDeleteDialog(true);
  };

  const handleEdit = (id: string) => {
    const user = userData.filter((user: any) => user._id === id);

    setIndividualUser(user[0]);

    setOpen(true);
  };

  const handleView = (id: string) => {
    const user = userData.filter((user: any) => {
      return user._id === id;
    });

    setIndividualUser(user[0]);

    setOpenViewDialog(true);
  };

  const handleAddWordCredits = (id: string) => {
    const user = userData.filter((user: any) => user._id === id);

    setIndividualUser(user[0]);

    setWordCreditsModal(true);
  };

  useEffect(() => {
    setUserData(rows.users);
  }, [rows.users]);

  return (
    <div>
      <Paper>
        {loader ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "50vh",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Paper>
            {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
            <TableContainer>
              <Table
                aria-labelledby="tableTitle"
                size={"medium"}
                aria-label="enhanced table"
              >
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={userData.length}
                />
                <TableBody>
                  {/* {stableSort(
                    userData ? userData : [],
                    getComparator(order, orderBy)
                  ) */}
                  {stableSort(userData, getComparator(order, orderBy)).map(
                    (row: any, index: number) => {
                      const isItemSelected = isSelected(row._id);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      const dateModifier = (date: string) => {
                        const dateObject = new Date(date);
                        const userDate = format(dateObject, "dd-MM-yyyy");
                        return userDate;
                      };

                      return (
                        <TableRow
                          hover
                          // role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row?._id}
                          selected={isItemSelected}
                        >
                          <TableCell
                            padding="checkbox"
                            onClick={(event) => handleClick(event, row?._id)}
                          ></TableCell>
                          <TableCell align="left">{row.name}</TableCell>
                          <TableCell align="left">{row.email}</TableCell>
                          <TableCell align="left">
                            {row.subscriptionStatus || "N/A"}
                          </TableCell>
                          <TableCell align="left">
                            {row.wordsCount || 0}
                          </TableCell>
                          <TableCell align="left">
                            {dateModifier(row?.createdAt)}
                          </TableCell>
                          <TableCell align="left">
                            {row?.lastLogin
                              ? dateModifier(row?.lastLogin)
                              : "NA"}
                          </TableCell>
                          <TableCell align="right">
                            <Actions
                              handleEdit={handleEdit}
                              id={row?._id}
                              handleDelete={handleDelete}
                              viewUser={true}
                              handleView={handleView}
                              handleAddWordCredits={handleAddWordCredits}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    }
                  )}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={8} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={rows.noOfUsers || 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        )}
      </Paper>
    </div>
  );
}

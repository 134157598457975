import EditIcon from "@mui/icons-material/Edit";
import { Box, Paper, Typography } from "@mui/material";
import React from "react";
import CheckIcon from "../images/pricing_check.png";
import LimitedIcon from "../images/limited.png";

const PlanCard = ({ plan, onEditPlanHandler }: any) => {
  return (
    <React.Fragment>
      <Paper
        sx={{
          width: ["100%", "100%", "47%", "30%"],
          p: [2, 10],
          m: "0 auto",
          maxWidth: "450px",
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          justifyContent: "center",
          borderRadius: "20px",
          minHeight: "500px",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 6,
          }}
        >
          <Typography
            sx={{
              fontSize: "26px",
              fontWeight: "500",
            }}
          >
            {plan.title}
          </Typography>
          <Box>
            <EditIcon
              onClick={() => onEditPlanHandler(plan?.id)}
              sx={{ mx: 3, cursor: "pointer", fontSize: "24px" }}
              color="action"
            />
          </Box>
        </Box>
        <Typography sx={{ fontWeight: "600", fontSize: "13px" }}>
          {plan.subHeading}
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography
            sx={{ fontWeight: "600", fontSize: "44px", color: "#66B5B2" }}
          >
            ${plan.price / 100}
          </Typography>
          <Typography
            sx={{ fontWeight: "500", fontSize: "24px", color: "#C4C4C4" }}
          >
            /mon
          </Typography>
        </Box>
        <Typography sx={{ fontSize: "13px" }}>{plan.description}</Typography>
        <Box
          sx={{
            // padding: "17px 30px",
            bgcolor: "#66B5B2",
            fontSize: "16px",
            color: "#fff",
            textAlign: "center",
            my: 5,
            height: "50px",
            borderRadius: "12px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            // width: ["100%", "80%"],
          }}
        >
          {plan.noOfWords} words generated
        </Box>
        {plan.features.map((feature: any, index: number) => (
          <FeatureDetails key={index} feature={feature} />
        ))}
      </Paper>
    </React.Fragment>
  );
};

export default PlanCard;

const FeatureDetails = ({ feature }: any) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
      {feature.includes("limited") ? (
        <img src={LimitedIcon} alt="" width={"20px"} height={"20px"} />
      ) : (
        <img src={CheckIcon} alt="" width={"20px"} height={"20px"} />
      )}
      <Typography my={1}>{feature.split(" - ")[0]}</Typography>
    </Box>
  );
};

import { Add as AddIcon } from "@mui/icons-material";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import React, { KeyboardEvent, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
import { URL } from "../../../services/API";
import { GET } from "../../../services/API/AxiosRequests";
import ConfirmDelete from "./ConfirmDelete";
import CreateUserForm from "./CreateUserForm";
import UserEnhancedTable from "./UserEnhancedTable";
import ViewTableInfo from "./ViewTableInfo";
import csvDownload from "json-to-csv-export";
import LoadingButton from "@mui/lab/LoadingButton";
import AddWordCredits from "./AddWordCredits";

export interface IUser {
  createdAt: string;
  email: string;
  name: string;
  stripeCustomerID: string;
  subscriptionID: string;
  __v: number;
  _id: string;
  userSubscriptionComment?: string;
  userSubscriptionFeedback?: string;
  subscriptionCount?: number;
}

function UserTable() {
  const [open, setOpen] = useState(false);

  const [loader, setLoader] = useState(true);

  const access_token = useSelector((state: any) => state.user.access_token);

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const [wordCreditsModal, setWordCreditsModal] = useState(false);

  const [openViewDialog, setOpenViewDialog] = useState(false);

  const [rows, setRows] = useState<{
    users: IUser[];
    currentPage: number;
    pages: number;
    noOfUsers: number;
  }>({
    users: [],
    currentPage: 0,
    pages: 0,
    noOfUsers: 0,
  });

  const [deletionId, setDeletionId] = useState("");

  const [userDeleted, setUserDeleted] = useState(false);

  const [search, setSearch] = useState(""); // State to store the search query

  const [csvLoading, setCsvLoading] = useState(false);

  const [individualUser, setIndividualUser] = useState<IUser>({
    createdAt: "",
    email: "",
    name: "",
    stripeCustomerID: "",
    subscriptionID: "",
    __v: -1,
    _id: "",
  });

  let isMounted = true;

  const GET_ALL_USERS = async () => {
    try {
      setLoader(true);

      const { users, currentPage, pages, noOfUsers } = await GET(
        `${URL.GET_ALL_USERS}?pageNumber=${1}&limit=${10}`,
        access_token
      );

      // Check if the component is still mounted before updating the state
      if (isMounted) {
        setRows({
          users,
          currentPage,
          pages,
          noOfUsers,
        });
      }
    } catch (err) {
      console.log(err);
    } finally {
      // Check if the component is still mounted before updating the loader state
      if (isMounted) {
        setLoader(false);
      }
    }
  };

  // Function to search users by email
  const searchUsersByEmail = async () => {
    try {
      setLoader(true);
      const { users, currentPage, pages, noOfUsers } = await GET(
        `${URL.GET_ALL_USERS}?pageNumber=${1}&limit=${10}&query=${search}`, // Add the email parameter to the URL
        access_token
      );

      // Check if the component is still mounted before updating the state
      if (isMounted) {
        setRows({
          users,
          currentPage,
          pages,
          noOfUsers,
        });
      }
    } catch (err) {
      console.log(err);
    } finally {
      // Check if the component is still mounted before updating the loader state
      if (isMounted) {
        setLoader(false);
      }
    }
  };

  const exportUsers = async () => {
    setCsvLoading(true);
    try {
      const { users } = await GET(URL.GET_ALL_USERS_CSV, access_token);
      csvDownload({ data: users });
      setCsvLoading(false);
    } catch (err) {
      console.log(err);
      setCsvLoading(false);
    }
  };

  const handleEnterKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      searchUsersByEmail();
    }
  };
  useEffect(() => {
    GET_ALL_USERS();

    setSearch("");
    // Cleanup function to set isMounted to false when the component unmounts
    return () => {
      isMounted = false;
    };
  }, [userDeleted]);

  return (
    <React.Fragment>
      <Helmet title="Users" />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Users
          </Typography>
        </Grid>

        <Grid item>
          <div>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setOpen(true)}
            >
              <AddIcon />
              CREATE USER
            </Button>
          </div>
        </Grid>
      </Grid>

      <Box my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div>
              <TextField
                id="outlined-basic"
                label="Search by email"
                variant="outlined"
                size="small"
                sx={{
                  w: "50%",
                  mb: 3,
                  mr: 3,
                }}
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
                onKeyPress={handleEnterKeyPress} // Apply onKeyPress to the TextField input
              />
              <Button
                variant="contained"
                onClick={searchUsersByEmail}
                disabled={loader}
              >
                {loader ? "Searching..." : "Search"}
              </Button>
              {/* Your component JSX here */}
            </div>
            <LoadingButton
              variant="contained"
              loading={csvLoading}
              onClick={exportUsers}
            >
              Export User csv
            </LoadingButton>
          </Box>

          <UserEnhancedTable
            rows={rows}
            setIndividualUser={setIndividualUser}
            setWordCreditsModal={setWordCreditsModal}
            wordCreditsModal={wordCreditsModal}
            open={open}
            setOpen={setOpen}
            setOpenDeleteDialog={setOpenDeleteDialog}
            deletionId={deletionId}
            setDeletionId={setDeletionId}
            loader={loader}
            setLoader={setLoader}
            openViewDialog={openViewDialog}
            setOpenViewDialog={setOpenViewDialog}
          />
        </Grid>
      </Grid>
      <CreateUserForm
        open={open}
        setOpen={setOpen}
        setIndividualUser={setIndividualUser}
        individualUser={individualUser}
        setUserUpdated={setUserDeleted}
      />
      <ConfirmDelete
        setUserDeleted={setUserDeleted}
        openDeleteDialog={openDeleteDialog}
        setOpenDeleteDialog={setOpenDeleteDialog}
        deletionId={deletionId}
        setDeletionId={setDeletionId}
        access_token={access_token}
      />
      <ViewTableInfo
        open={openViewDialog}
        setOpen={setOpenViewDialog}
        setIndividualUser={setIndividualUser}
        individualUser={individualUser}
      />
      <AddWordCredits
        open={wordCreditsModal}
        setOpen={setWordCreditsModal}
        setIndividualUser={setIndividualUser}
        individualUser={individualUser}
      />
    </React.Fragment>
  );
}

export default UserTable;

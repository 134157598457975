import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import { URL } from "../../../services/API";
import { POST } from "../../../services/API/AxiosRequests";

export default function AddWordCredits({
  open,
  setOpen,
  individualUser,
  setIndividualUser,
}: any) {
  const [loader, setLoader] = useState(false);

  const [words, setWords] = useState("");

  const access_token = useSelector((state: any) => state.user.access_token);

  const AddCredits = async () => {
    if (!words || words === "0") {
      alert("Please enter words");
      return;
    }

    try {
      setLoader(true);

      const payload = {
        userID: individualUser._id,
        words: words,
      };

      const updatedUser = await POST(
        URL.ADD_WORD_CREDITS,
        payload,
        access_token
      );

      setIndividualUser(updatedUser);

      setLoader(false);

      setOpen(false);

      setWords("");

      alert("Credits added successfully");
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Paper>
      <Dialog
        open={open}
        onClose={() => {
          setIndividualUser({
            createdAt: "",
            email: "",
            name: "",
            stripeCustomerID: "",
            subscriptionID: "",
            __v: -1,
            _id: "",
          });
          setOpen(false);
        }}
      >
        <Box sx={{ width: ["100%", "600px"] }}>
          <>
            <Typography variant="h3" textAlign={"center"} sx={{ mt: 5 }}>
              Add Word Credits
            </Typography>
            <Box
              sx={{
                p: 5,
                paddingX: "30px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography variant="h4">User Name:</Typography>
              <Typography sx={{ ml: 3 }} fontSize={15}>
                {individualUser.name}
              </Typography>
            </Box>
            <Box
              sx={{
                p: 5,
                paddingX: "30px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography variant="h4">Bonus Credits:</Typography>
              <Typography sx={{ ml: 3 }} fontSize={15}>
                {individualUser.addons}
              </Typography>
            </Box>
            <Box
              sx={{
                p: 5,
                paddingX: "30px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography variant="h4">Available Credit : </Typography>
              <Typography sx={{ ml: 3 }} fontSize={15}>
                {String(
                  individualUser?.wordsLimit - individualUser?.wordsCount ?? 0
                )}
              </Typography>
            </Box>

            <Box
              sx={{
                p: 5,
                paddingX: "30px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography variant="h4">Total Credits : </Typography>
              <Typography sx={{ ml: 3 }} fontSize={15}>
                {String(
                  individualUser?.wordsLimit
                    ? individualUser?.wordsLimit + individualUser?.addons
                    : 0
                )}{" "}
                words
              </Typography>
            </Box>
            {/* Create a input field */}
            <Box
              sx={{
                p: 5,
                paddingX: "30px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <TextField
                id="outlined-basic"
                label="Add Words"
                variant="outlined"
                size="medium"
                sx={{
                  w: "50%",
                  mb: 3,
                  mr: 3,
                }}
                value={words}
                onChange={(e) => {
                  const input = e.target.value;
                  if (/^\d*$/.test(input)) {
                    setWords(input);
                  }
                }}
              />
            </Box>

            <DialogActions sx={{ mb: "10px", mr: "15px" }}>
              <Button
                type="button"
                color="primary"
                variant="contained"
                onClick={AddCredits}
              >
                {loader ? (
                  <CircularProgress size={20} sx={{ color: "#fff" }} />
                ) : (
                  "Add"
                )}
              </Button>

              <Button
                type="button"
                color="primary"
                variant="outlined"
                onClick={() => {
                  setIndividualUser({
                    createdAt: "",
                    email: "",
                    name: "",
                    stripeCustomerID: "",
                    subscriptionID: "",
                    __v: -1,
                    _id: "",
                  });
                  setOpen(false);
                }}
              >
                Cancel
              </Button>
            </DialogActions>
          </>
        </Box>
      </Dialog>
    </Paper>
  );
}

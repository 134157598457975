import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import FeatureDetails from "../../../components/FeatureDetails";
import PlanCard from "../../../components/PlanCard";
import useAppSelector from "../../../hooks/useAppSelector";
import { URL } from "../../../services/API";
import { GET, POST } from "../../../services/API/AxiosRequests";

import DoneIcon from "@mui/icons-material/Done";

export interface IPlans {
  id: number;
  title: string;
  subHeading: string;
  price: string;
  description: string;
  noOfWords: string;
  features: string[];
}

const Subscription = () => {
  const [open, setOpen] = useState(false);

  const [plan, setPlan] = useState<IPlans>({
    id: -1,
    title: "",
    subHeading: "",
    price: "",
    description: "",
    noOfWords: "",
    features: [],
  });

  const [planUpdate, setPlanUpdate] = useState();

  const [stripePlans, setStripePlans] = useState([]);

  const [loader, setLoader] = useState(false);

  const getPlans = async (): Promise<void> => {
    setLoader(true);
    try {
      const { data } = await GET(URL.STRIPE_PLANS);
      setStripePlans(
        data.map((plan: any) => ({
          id: plan.id,
          title: plan.name,
          subHeading: plan.metadata.subHeading,
          price: plan.default_price.unit_amount,
          description: plan.metadata.description,
          noOfWords: plan.metadata.wordsLimit,
          features: JSON.parse(`[${plan.metadata.features.slice(1, -1)}]`)[0],
        }))
      );
    } catch (err) {
      console.error(err);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    getPlans();
  }, [planUpdate]);

  const onEditPlanHandler = (value: any) => {
    setOpen(true);

    const stripePlan: any = stripePlans.filter((val: any) => {
      return val.id === value;
    });

    stripePlan[0] &&
      setPlan({ ...stripePlan[0], price: stripePlan[0].price / 100 });
  };

  return (
    <>
      <Helmet title="Subscriptions" />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 10,
          gap: "10px",
        }}
      >
        <Typography sx={{ fontSize: "20px", fontWeight: "700" }}>
          Subscription Management
        </Typography>
      </Box>
      {loader ? (
        <Box sx={{ paddingTop: "5rem", textAlign: "center" }}>
          <CircularProgress />
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            gap: "20px",
            flexWrap: "wrap",
          }}
        >
          {stripePlans.map((plan: any) => (
            <PlanCard
              key={plan?.id}
              plan={plan}
              onEditPlanHandler={onEditPlanHandler}
            />
          ))}
        </Box>
      )}

      <CreateNewPlan
        open={open}
        setOpen={setOpen}
        plan={plan}
        setPlan={setPlan}
        setPlanUpdate={setPlanUpdate}
        stripePlans={stripePlans}
        planUpdate={planUpdate}
      />
    </>
  );
};

export default Subscription;

function CreateNewPlan({
  open,
  setOpen,
  plan,
  setPlan,
  setPlanUpdate,
  stripePlans,
}: any) {
  const [updatingFeature, setUpdatingFeature] = useState<boolean>(false);

  const [newFeature, setNewFeature] = useState("");

  const [loading, setLoading] = useState(false);

  const [isAdding, setIsAdding] = useState(false);

  const user_access_token = useAppSelector((state) => state.user.access_token);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    const target = e.target as any;
    e.preventDefault();

    setLoading(true);

    const formattedNumber = formatNumber(
      target.noOfWords.value || plan.noOfWords
    );

    try {
      const payload = {
        name: target.title?.value,
        subHeading: target.subHeading?.value,
        description: target.description?.value,
        wordsLimit: formattedNumber,
        // price: Number(target.price?.value),
        features: `'${JSON.stringify(plan.features)}'`,
      };

      const updated_plan = await POST(
        URL.UPDATE_STRIPE_PLAN(plan.id),
        payload,
        user_access_token
      );

      setPlanUpdate(updated_plan);

      // Update Price
      const existingPlan = stripePlans.filter((val: any) => {
        if (val?.id === plan?.id) {
          return val;
        }
      });
      const oldPrice = existingPlan[0]?.price / 100;
      if (Number(target.price?.value) !== oldPrice) {
        const data = {
          price: Math.ceil(Number(target.price?.value) * 100),
        };
        const updated_price = await POST(
          URL.UPDATE_STRIPE_PLAN_PRICE(plan.id),
          data,
          user_access_token
        );
        setPlanUpdate(updated_price);
      }

      setOpen(false);
    } catch (error: any) {
      const errorMessage = error?.response?.data?.message;

      console.error("error", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteFeature = (featureIndex: number) => {
    const newFeatures = plan.features.filter(
      (feature: any, index: number) => index !== featureIndex
    );
    setPlan({ ...plan, features: newFeatures });
  };

  const handleEditFeature = (feature: string, index: number) => {
    let newFeat = [...plan.features];

    newFeat[index] = feature;

    setPlan({ ...plan, features: newFeat });
  };

  const formatNumber = (number: string) => {
    const regex = /(\d)(?=(\d{3})+(?!\d))/g;
    return number.toString().replace(regex, "$1,");
  };

  const handleAddFeature = () => {
    if (!newFeature) return setIsAdding(false);

    if (newFeature.trim() !== "") {
      const newFeatures = [...plan.features, newFeature];

      setPlan({ ...plan, features: newFeatures });

      setNewFeature(""); // Clear the input field after adding the feature

      setIsAdding(false);
    }
  };

  return (
    <Paper>
      <Dialog
        open={open}
        sx={{
          "& .MuiDialog-paper": {
            width: ["100%", "auto"],
            maxWidth: ["100vw", "700px"],
            borderRadius: "10px",
            margin: ["10px"],
          },
        }}
        onClose={() => {
          setPlan({
            id: -1,
            planName: "",
            tagline: "",
            price: "",
            description: "",
            noOfWords: "",
            features: [],
          });
          setOpen(false);
        }}
        aria-labelledby="Create New Plan"
      >
        <form onSubmit={handleSubmit} style={{ padding: "20px 10px" }}>
          <DialogTitle id="create-new-plan" sx={{ fontSize: "24px" }}>
            {plan.id !== -1 ? "Edit Plan" : "Add New Plan"}
          </DialogTitle>
          <DialogContent
            sx={{
              padding: ["20px 5px", "20px 24px"],
            }}
          >
            <TextField
              margin="dense"
              id="title"
              label="Plan Name"
              name="title"
              type="text"
              fullWidth
              required
              value={plan?.title || ""}
              onChange={(e) =>
                setPlan({
                  ...plan,
                  title: e.target.value,
                })
              }
            />

            <TextField
              margin="dense"
              id="subHeading"
              name="subHeading"
              label="Tagline"
              type="text"
              fullWidth
              required={true}
              value={plan?.subHeading || ""}
              onChange={(e) =>
                setPlan({
                  ...plan,
                  subHeading: e.target.value,
                })
              }
            />

            <TextField
              margin="dense"
              id="price"
              name="price"
              label="Price"
              type="text"
              fullWidth
              required={true}
              value={plan?.price || ""}
              onChange={(e) =>
                setPlan({
                  ...plan,
                  price: e.target.value,
                })
              }
            />

            <TextField
              margin="dense"
              id="description"
              name="description"
              label="Description"
              type="text"
              multiline={true}
              maxRows={6}
              fullWidth
              required={true}
              value={plan?.description || ""}
              onChange={(e) =>
                setPlan({
                  ...plan,
                  description: e.target.value,
                })
              }
            />

            <TextField
              margin="dense"
              id="noOfWords"
              name="noOfWords"
              label="No. of words"
              type="text"
              fullWidth
              required={true}
              value={plan?.noOfWords || ""}
              onChange={(e) =>
                setPlan({
                  ...plan,
                  noOfWords: e.target.value,
                })
              }
            />

            {
              <List
                sx={{
                  border: "1px solid #e0e0e0",
                  borderRadius: "5px",
                  width: "100%",
                  mt: 2,
                  boxShadow: "0px 0px 5px 0px #e0e0e0",
                }}
              >
                <Typography variant="h4" sx={{ pl: 2, pt: 2 }}>
                  Features:
                </Typography>
                {plan?.features?.map((feature: any, index: number) => (
                  <FeatureDetails
                    key={index}
                    index={index}
                    feature={feature}
                    onEditFeatureHandler={handleEditFeature}
                    setUpdatingFeature={setUpdatingFeature}
                    onDelete={handleDeleteFeature}
                  />
                ))}

                {/* Add Feature */}

                <ListItem>
                  {isAdding && (
                    <TextField
                      value={newFeature}
                      onChange={(e) => setNewFeature(e.target.value)}
                      fullWidth
                    />
                  )}
                  <ListItemSecondaryAction>
                    {isAdding && (
                      <IconButton edge="end" onClick={handleAddFeature}>
                        <DoneIcon color="success" sx={{ fontSize: "1.5rem" }} />
                      </IconButton>
                    )}
                  </ListItemSecondaryAction>
                </ListItem>

                <Divider sx={{ my: 2 }} />
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    mr: 2,
                    mt: 2,
                  }}
                >
                  <Button
                    sx={{ px: 10 }}
                    variant="contained"
                    onClick={() => {
                      setIsAdding(true);
                    }}
                  >
                    Add feature
                  </Button>
                  {isAdding && (
                    <Button
                      color="error"
                      sx={{ px: 10, ml: 2 }}
                      variant="contained"
                      onClick={() => {
                        setIsAdding(false);
                      }}
                    >
                      Close
                    </Button>
                  )}
                </Box>
              </List>
            }
          </DialogContent>
          {updatingFeature && (
            <Box
              sx={{
                border: "1px solid red",
                borderRadius: "5px",
                margin: "0 24px 10px 24px",
                boxShadow: "0px 0px 5px 0px #e0e0e0",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                height: "50px",
                transition: "all 0.3s ease-in-out",
              }}
            >
              <InfoOutlinedIcon sx={{ ml: 2 }} />
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: "bold",
                  color: "red",
                  ml: 2,
                }}
              >
                Please update the feature before saving the changes
              </Typography>
              {/* <Tooltip title="Please update the feature" placement="top">
              </Tooltip> */}
            </Box>
          )}
          <DialogActions sx={{ mb: "10px", mr: "15px" }}>
            <Button
              type="button"
              color="primary"
              variant="outlined"
              onClick={() => {
                setPlan({
                  id: -1,
                  planName: "",
                  tagline: "",
                  price: "",
                  description: "",
                  noOfWords: "",
                  features: [],
                });
                setOpen(false);
              }}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              color="primary"
              variant="outlined"
              sx={{ minWidth: "120px", minHeight: "35px" }}
              disabled={updatingFeature ? true : false}
            >
              {loading ? (
                <CircularProgress size={20} sx={{ ml: 2 }} />
              ) : (
                "Save Changes"
              )}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </Paper>
  );
}

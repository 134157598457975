import { Cancel as CancelOutlinedIcon } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Paper,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { URL } from "../../../services/API";
import { DELETE } from "../../../services/API/AxiosRequests";

export default function ConfirmDelete({
  openDeleteDialog,
  setOpenDeleteDialog,
  deletionId,
  setDeletionId,
  access_token,
  setUserDeleted,
}: any) {
  const [loader, setLoader] = useState(false);

  const onDelete = async () => {
    // bearer-token will send in delete api instead of empty string when admin is authentic
    try {
      setLoader(true);

      const { message } = await DELETE(URL.DELETE_USER, access_token, {
        userID: deletionId,
      });
      setUserDeleted(true);
    } catch (err) {
      console.error(err);
    } finally {
      setLoader(false);
      setOpenDeleteDialog(false);
      setDeletionId("");
    }
  };

  return (
    <Paper>
      <Dialog open={openDeleteDialog} aria-labelledby="Delete User">
        <DialogContent sx={{ textAlign: "center" }}>
          <CancelOutlinedIcon color="error" sx={{ fontSize: "2rem", my: 2 }} />
          <Typography variant="h4" my={2}>
            Are your sure?
          </Typography>
          <Typography>
            Do you really want to delete this user ? This process cannot be
            undone.
          </Typography>
        </DialogContent>
        <DialogActions sx={{ scale: "95%", mb: 1 }}>
          <Button
            type="button"
            color="error"
            variant="contained"
            onClick={() => {
              setDeletionId("");
              setOpenDeleteDialog(false);
            }}
          >
            Cancel
          </Button>
          <LoadingButton
            type="button"
            color="primary"
            variant="contained"
            loading={loader ? true : false}
            onClick={() => onDelete()}
          >
            Delete
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Paper>
  );
}

import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";
import { URL } from "../services/API";
import { GET, POST, PUT } from "../services/API/AxiosRequests";
import { Helmet } from "react-helmet-async";

interface ITemplate {
  _id: string;
  heading: string;
  description: string;
  placeholder: string;
  buttonText: string;
  ImageUrl: string;
  __v: number;
  prompt: string;
  count: number;
}
const SimpleForm = () => {
  const [template, setTemplate] = useState<ITemplate>({
    _id: "",
    heading: "",
    description: "",
    placeholder: "",
    buttonText: "",
    ImageUrl: "",
    __v: 0,
    count: 0,
    prompt: "",
  });

  const [imageUrl, setImageUrl] = useState<string>("");

  const [isUploading, setIsUploading] = useState(false);

  const [loading, setLoading] = useState(false);

  const [submitLoader, setSubmitLoader] = useState(false);

  const templateFormRef = useRef<any>();

  const inputRef = useRef<HTMLInputElement | null>(null);

  const { id } = useParams();

  const access_token = useSelector((state: any) => state.user.access_token);

  const navigate = useNavigate();

  const getIndividualTemplate = async () => {
    try {
      const template = await GET(`${URL.SINGLE_TEMPLATE_BY_ID(id)}`);

      setImageUrl(template?.ImageUrl);

      setTemplate(template);

      setLoading(true);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (!id) {
      setTemplate({
        _id: "",
        heading: "",
        description: "",
        placeholder: "",
        buttonText: "",
        ImageUrl: "",
        __v: 0,
        count: 0,
        prompt: "",
      });
      setImageUrl("");
      setLoading(true);
    } else getIndividualTemplate();
  }, [id]);

  const handleSubmit = async (e: any) => {
    setSubmitLoader(true);

    e.preventDefault();

    const target = e.target;

    if (!imageUrl) return;
    try {
      const payload = {
        heading: target.heading.value,
        description: target.description.value,
        placeholder: target.placeholder.value,
        ImageUrl: imageUrl,
        buttonText: target.buttonText.value,
        prompt: target.prompt.value,
      };

      // Template ID is present, so we are updating the template
      id
        ? await PUT(`${URL.UPDATE_TEMPLATE}/${id}`, access_token, payload)
        : await POST(URL.ADD_TEMPLATE, payload);

      setImageUrl("");

      navigate("/template/view");

      templateFormRef?.current.reset();
    } catch (error: any) {
      const errorMessage = error.message;

      console.error("error", errorMessage);
    } finally {
      setSubmitLoader(false);
    }
  };

  const handleFileChange = async (event: any) => {
    event.preventDefault();

    setIsUploading(true);

    let fileObj;
    if (event?.dataTransfer === undefined) {
      fileObj = event.target.files && event.target.files[0];
    } else {
      fileObj = event.dataTransfer.files[0];
    }
    const file = new FormData();

    file.append("file", fileObj);
    try {
      const { ImageUrl } = await POST(URL.UPLOAD_IMAGE, file);

      setImageUrl(ImageUrl);
    } catch (error) {
      console.log({ error });
    } finally {
      setIsUploading(false);
    }
  };

  function isHTMLInputElement(element: any): element is HTMLInputElement {
    return element instanceof HTMLInputElement;
  }

  const handleClick = () => {
    if (!inputRef.current) return;

    if (isHTMLInputElement(inputRef.current)) {
      inputRef.current.click();
    }
  };

  return (
    <>
      <Helmet title="Add New Template" />
      {!loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "50vh",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box
          sx={{
            width: ["100%", "50%", "50%", "40%"],
            margin: ["20px auto", "50px auto"],
          }}
        >
          <Typography variant="h3">
            {id ? "Edit Template" : "Add New Template"}
          </Typography>
          <form onSubmit={handleSubmit} ref={templateFormRef}>
            <TextField
              label="Heading"
              name="heading"
              placeholder="Enter heading here"
              variant="outlined"
              fullWidth
              margin="normal"
              required
              value={template.heading || ""}
              onChange={(e) =>
                setTemplate({
                  ...template,
                  heading: e.target.value,
                })
              }
            />
            <TextField
              margin="normal"
              fullWidth
              label="Description"
              name="description"
              placeholder="Enter description here"
              variant="outlined"
              required
              value={template?.description}
              onChange={(e) =>
                setTemplate({
                  ...template,
                  description: e.target.value,
                })
              }
            />
            <TextField
              margin="normal"
              fullWidth
              label="Placeholder"
              name="placeholder"
              placeholder="Enter input here"
              variant="outlined"
              required
              value={template?.placeholder}
              onChange={(e) =>
                setTemplate({
                  ...template,
                  placeholder: e.target.value,
                })
              }
              sx={{
                textOverflow: "ellipsis",
                lineClamp: "1",
              }}
            />

            <TextField
              margin="normal"
              fullWidth
              label="Button Text"
              name="buttonText"
              placeholder="Button text"
              variant="outlined"
              required
              value={template?.buttonText}
              onChange={(e) =>
                setTemplate({
                  ...template,
                  buttonText: e.target.value,
                })
              }
            />

            <TextField
              margin="normal"
              fullWidth
              label="Prompt"
              name="prompt"
              placeholder="Enter System Prompt here"
              variant="outlined"
              required
              value={template?.prompt}
              onChange={(e) =>
                setTemplate({
                  ...template,
                  prompt: e.target.value,
                })
              }
            />

            {imageUrl ? (
              <Box
                sx={{
                  border: "1px solid #D7D7D7",
                  p: 1,
                  textAlign: "center",
                  mt: 3.5,
                }}
                onDragOver={(e) => e.preventDefault()}
                onDrop={handleFileChange}
              >
                <Box
                  sx={{
                    width: "128px",
                    height: "128px",
                    position: "relative",
                    margin: "0 auto",
                    overflow: "hidden",
                  }}
                >
                  <img
                    src={imageUrl}
                    style={{
                      margin: "auto",
                      position: "absolute",
                      left: "50%",
                      top: "50%",
                      maxWidth: "128px",
                      maxHeight: "128px",
                      width: "auto",
                      transform: "translate(-50%,-50%)",
                    }}
                    alt="Cover Image"
                  />
                </Box>
                <input
                  style={{ display: "none" }}
                  ref={inputRef}
                  accept="image/*"
                  type="file"
                  name="ImageUrl"
                  onChange={handleFileChange}
                />
                <LoadingButton
                  variant="contained"
                  onClick={handleClick}
                  sx={{ my: 2 }}
                >
                  Add new
                </LoadingButton>
              </Box>
            ) : (
              <Box
                sx={{
                  border: "1px solid #D7D7D7",
                  p: 4,
                  textAlign: "center",
                }}
                onDragOver={(e) => e.preventDefault()}
                onDrop={handleFileChange}
              >
                <Typography> {"Drag and drop file"}</Typography>

                <Typography my={1}>or</Typography>

                <input
                  style={{ display: "none" }}
                  ref={inputRef}
                  accept="image/png, image/jpeg"
                  type="file"
                  name="ImageUrl"
                  onChange={handleFileChange}
                />
                <LoadingButton
                  variant="contained"
                  onClick={handleClick}
                  loading={isUploading ? true : false}
                >
                  Browse
                </LoadingButton>
              </Box>
            )}
            <Typography variant="caption" display="block" gutterBottom mt={2}>
              For best results, use an image at least 128px by 128px in .jpg or
              .png format
            </Typography>

            <Box sx={{ textAlign: "right", mt: 2 }}>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                disabled={imageUrl ? false : true}
                sx={{ minWidth: "120px", minHeight: "35px" }}
              >
                {submitLoader ? (
                  <CircularProgress size={20} sx={{ ml: 2 }} />
                ) : (
                  "Submit"
                )}
              </Button>
            </Box>
          </form>
        </Box>
      )}
    </>
  );
};

export default SimpleForm;

import { SidebarItemsType } from "../../types/sidebar";

import { CreditCard, Layout, Sliders } from "react-feather";

const pagesSection = [
  {
    href: "/dashboard/users",
    icon: Sliders,
    title: "Users",
  },
  {
    href: "/template",
    icon: Layout,
    // title: "Manage Template",
    title: "Template",
    children: [
      {
        href: "/template/add",
        title: "Add New",
      },
      {
        href: "/template/view",
        title: "View",
      },
    ],
  },
  {
    href: "/rephraser",
    icon: Layout,
    title: "View Rephraser",
  },
  {
    href: "/subscription/plan",
    icon: CreditCard,
    title: "Subscription",
  },

  {
    href: "/dashboard/analytics",
    icon: Layout,
    title: "Analytics",
  },

  {
    href: "/support/helpdesk",
    icon: Layout,
    title: "Support & Helpdesk",
  },
] as SidebarItemsType[];

const navItems = [
  {
    title: "Pages",
    pages: pagesSection,
  },
];

export default navItems;

import React from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";

import { Avatar, Box, Paper, Typography } from "@mui/material";

import { ReactComponent as Logo } from "../../vendor/logo.svg";
import SignInComponent from "../../components/auth/SignIn";
import { Navigate, useNavigate } from "react-router-dom";

const Brand = styled(Logo)`
  fill: ${(props) => props.theme.palette.primary.main};
  width: 64px;
  height: 64px;
  margin-bottom: 32px;
`;

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

// const BigAvatar = styled(Avatar)`
//   width: 92px;
//   height: 92px;
//   text-align: center;
//   margin: 0 auto ${(props) => props.theme.spacing(5)};
// `;

function SignIn() {
  return (
    <React.Fragment>
      {/* <Brand /> */}

      <Wrapper
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "80vh",
        }}
      >
        <Box>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <img src="/logo_black.png" width={320} height={115} />
          </Box>
          <Helmet title="Sign In" />
          {/* <BigAvatar alt="Lucy" src="/static/img/avatars/avatar-1.jpg" /> */}

          <Typography component="h1" variant="h3" align="center" gutterBottom>
            Sign in
          </Typography>

          <SignInComponent />
        </Box>
      </Wrapper>
    </React.Fragment>
  );
}

export default SignIn;

import React from "react";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { URL } from "../services/API";
import { GET, POST } from "../services/API/AxiosRequests";
import { useSelector } from "react-redux";
import { LoadingButton } from "@mui/lab";

export interface IForm {
  _id: string;
  userID: string;
  username: string;
  email: string;
  message: string;
  createdAt: string;
  __v: number;
}

export default function HelpDeskReplyForm() {
  const [loader, setLoader] = useState(false);

  const [helpRequest, setHelpRequest] = useState<IForm>({
    _id: "",
    userID: "",
    username: "",
    email: "",
    message: "",
    createdAt: "",
    __v: -1,
  });
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const access_token = useSelector((state: any) => state.user.access_token);

  const USER_HELP_REQUEST = async () => {
    try {
      const userRequest = await GET(
        `${URL.USER_HELP_REQUEST}/${id}`,
        access_token
      );
      setHelpRequest(userRequest);
      userRequest ? setLoading(true) : setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (!id) {
      setHelpRequest({
        _id: "",
        userID: "",
        username: "",
        email: "",
        message: "",
        createdAt: "",
        __v: -1,
      });
    } else USER_HELP_REQUEST();
  }, []);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const target = e.target;
    try {
      setLoader(true);

      const payload = {
        to: helpRequest?.email,
        reply: target.reply.value,
      };

      const response = await POST(
        `${URL.SEND_HELP_REPLY}`,
        payload,
        access_token
      );
      if (response) navigate("/support/helpdesk");
    } catch (error: any) {
      const errorMessage = error.message;
      console.error("error", errorMessage);
    } finally {
      setLoader(false);
    }
  };

  return (
    <React.Fragment>
      {!loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "50vh",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Paper
          sx={{
            width: ["100%", "50%", "50%", "40%"],
            margin: ["20px auto", "50px auto"],
            padding: "20px 25px",
          }}
        >
          <Typography variant="h3">Reply</Typography>
          <form onSubmit={handleSubmit}>
            <TextField
              margin="normal"
              id="username"
              label="Name"
              name="username"
              inputProps={{ minLength: 3 }}
              type="text"
              fullWidth
              required
              value={helpRequest?.username || ""}
            />

            <TextField
              margin="normal"
              id="email"
              label="Email Address"
              type="email"
              fullWidth
              name="email"
              required={true}
              value={helpRequest?.email || ""}
            />

            <TextField
              margin="normal"
              id="message"
              name="message"
              label="Question"
              type="text"
              fullWidth
              multiline={true}
              minRows={3}
              maxRows={5}
              required
              value={helpRequest?.message || ""}
            />

            <TextField
              margin="normal"
              id="reply"
              name="reply"
              label="Reply"
              type="text"
              fullWidth
              multiline={true}
              maxRows={6}
              required={true}
            />

            <Box
              sx={{
                display: "flex",
                gap: "10px",
                justifyContent: "flex-end",
                mt: 2,
              }}
            >
              <Button
                type="button"
                color="primary"
                variant="outlined"
                onClick={() => navigate("/support/helpdesk")}
              >
                Cancel
              </Button>
              <LoadingButton
                type="submit"
                color="primary"
                variant="contained"
                loading={loader ? true : false}
              >
                Send
              </LoadingButton>
            </Box>
          </form>
        </Paper>
      )}
    </React.Fragment>
  );
}

import React, { useState } from "react";
import {
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  TextField,
  Button,
  Box,
  Divider,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DoneIcon from "@mui/icons-material/Done";
import DeleteIcon from "@mui/icons-material/Delete";

const FeatureDetails = ({
  feature,
  onEditFeatureHandler,
  index,
  setUpdatingFeature,
  onDelete,
}: any) => {
  const [isEditing, setIsEditing] = useState(false);

  const [editedValue, setEditedValue] = useState(feature);

  // Function to handle saving the edited feature
  const handleSaveFeature = () => {
    setIsEditing(false);

    setUpdatingFeature(false);

    onEditFeatureHandler(editedValue, index);
  };

  return (
    <>
      <Divider sx={{ my: 2 }} />

      <ListItem>
        {isEditing ? (
          <TextField
            value={editedValue}
            onChange={(e) => setEditedValue(e.target.value)}
            fullWidth
          />
        ) : (
          <ListItemText
            primary={
              feature.includes("limited") ? feature.split("-")[0] : feature
            }
          />
        )}
        <ListItemSecondaryAction>
          {isEditing ? (
            <IconButton edge="end" onClick={handleSaveFeature}>
              <DoneIcon color="success" sx={{ fontSize: "1.5rem" }} />
            </IconButton>
          ) : (
            <>
              <IconButton
                edge="end"
                onClick={() => {
                  setIsEditing(true);
                  setUpdatingFeature(true);
                }}
                disabled={isEditing}
              >
                <EditIcon />
              </IconButton>
              <IconButton
                edge="end"
                onClick={() => {
                  onDelete(index);
                }}
                disabled={isEditing}
              >
                <DeleteIcon color="error" />
              </IconButton>
            </>
          )}
        </ListItemSecondaryAction>
      </ListItem>
      <Box sx={{ display: "flex", justifyContent: "flex-end", mr: 4, mb: 0 }}>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => {
            onEditFeatureHandler(`${feature} - limited`, index);
          }}
        >
          Mark as Limited
        </Button>
        {feature.includes("limited") && (
          <Button
            variant="contained"
            color="warning"
            onClick={() => {
              onEditFeatureHandler(
                `${feature.replace("- limited", "")}`,
                index
              );
            }}
            sx={{ ml: 2 }}
          >
            Un-Mark
          </Button>
        )}
      </Box>
    </>
  );
};

export default FeatureDetails;

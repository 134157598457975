import { Box } from "@mui/material";
import { Edit as EditIcon } from "@mui/icons-material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import AddIcon from "@mui/icons-material/Add";

interface ActionsProps {
  handleDelete?: any;
  handleEdit?: any;
  handleView?: any;
  id?: string | number;
  viewUser: boolean;
  handleAddWordCredits?: any;
}

export default function Actions({
  handleEdit,
  id,
  handleDelete,
  viewUser,
  handleView,
  handleAddWordCredits,
}: ActionsProps) {
  return (
    <Box sx={{ display: "flex", gap: 6, justifyContent: "end" }}>
      <AddIcon
        color="primary"
        sx={{ cursor: "pointer" }}
        onClick={() => handleAddWordCredits(id)}
      />
      {viewUser && (
        <VisibilityIcon
          color="primary"
          sx={{ cursor: "pointer" }}
          onClick={() => handleView(id)}
        />
      )}
      <EditIcon
        color="primary"
        sx={{ cursor: "pointer" }}
        onClick={() => handleEdit(id)}
      />
      <DeleteForeverIcon
        color="error"
        sx={{ cursor: "pointer" }}
        onClick={() => handleDelete(id)}
      />
    </Box>
  );
}

import { CacheProvider } from "@emotion/react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Provider } from "react-redux";
import { Navigate, useLocation, useRoutes } from "react-router-dom";

import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";

import "./i18n";
import routes from "./routes";
import createTheme from "./theme";

import { persistStore } from "redux-persist";
import useTheme from "./hooks/useTheme";
import { store } from "./redux/store";

import createEmotionCache from "./utils/createEmotionCache";

import { AuthProvider } from "./contexts/JWTContext";

import FormioContrib from "@formio/contrib";
import { Formio } from "@formio/react";
import { PersistGate } from "redux-persist/integration/react";
import useAppSelector from "./hooks/useAppSelector";
import "./styles/App.css";

let persistor = persistStore(store);
Formio.use(FormioContrib);

const clientSideEmotionCache = createEmotionCache();

const HigherOrderComponent: React.FunctionComponent | undefined | any = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const userAvailable = useAppSelector((state: any) => state.user.access_token);

  const loc = useLocation();

  const isAuthUrl =
    window.location.href.includes("/auth/sign-in") ||
    window.location.href.includes("/auth/sign-up");

  if (userAvailable) {
    if (loc.pathname === "/") {
      return <Navigate to={"/dashboard/users"} />;
    }
    if (isAuthUrl) {
      return <Navigate to={"/dashboard/users"} />;
    }
  } else {
    if (!isAuthUrl || loc.pathname === "/") {
      return <Navigate to={"/auth/sign-in"} />;
    }
  }
  return children;
};

function App({ emotionCache = clientSideEmotionCache }) {
  const content = useRoutes(routes);

  const { theme } = useTheme();
  return (
    <CacheProvider value={emotionCache}>
      <HelmetProvider>
        <Helmet
          titleTemplate="%s | College Cat"
          defaultTitle="College Cat Admin Panel"
        />
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            {/* @ts-ignore */}
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <MuiThemeProvider theme={createTheme(theme)}>
                <AuthProvider>
                  <HigherOrderComponent>{content}</HigherOrderComponent>
                </AuthProvider>
              </MuiThemeProvider>
            </LocalizationProvider>
          </PersistGate>
        </Provider>
      </HelmetProvider>
    </CacheProvider>
  );
}

export default App;

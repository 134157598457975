import styled from "@emotion/styled";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";

import ReplyIcon from "@mui/icons-material/Reply";
import {
  Box,
  CircularProgress,
  Grid,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import { useSelector } from "react-redux";
import { URL } from "../services/API";
import { GET } from "../services/API/AxiosRequests";

const Paper = styled(MuiPaper)(spacing);

type RowType = {
  [key: string]: string | number;
  _id: string;
  username: string;
  email: string;
  question: string;
};
function descendingComparator(a: RowType, b: RowType, orderBy: string) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order: "desc" | "asc", orderBy: string) {
  return order === "desc"
    ? (a: RowType, b: RowType) => descendingComparator(a, b, orderBy)
    : (a: RowType, b: RowType) => -descendingComparator(a, b, orderBy);
}

function stableSort(
  array: Array<RowType>,
  comparator: (a: RowType, b: RowType) => number
) {
  const stabilizedThis = array.map((el: RowType, index: number) => ({
    el,
    index,
  }));
  stabilizedThis.sort((a, b) => {
    const order = comparator(a.el, b.el);
    if (order !== 0) return order;
    return a.index - b.index;
  });

  return stabilizedThis.map((element) => element.el);
}

type HeadCell = {
  id: string;
  alignment: "left" | "center" | "right" | "justify" | "inherit" | undefined;
  label: string;
  disablePadding?: boolean;
};
const headCells: Array<HeadCell> = [
  { id: "name", alignment: "left", label: "Name" },
  { id: "email", alignment: "left", label: "Email" },
  { id: "question", alignment: "left", label: "Question" },
  { id: "request date", alignment: "left", label: "Request Date" },
  { id: "request time", alignment: "left", label: "Request Time" },
  { id: "reply", alignment: "right", label: "Reply" },
];

type EnhancedTableHeadProps = {
  numSelected: number;
  order: "desc" | "asc";
  orderBy: string;
  rowCount: number;
  onSelectAllClick: (e: any) => void;
  onRequestSort: (e: any, property: string) => void;
};
const EnhancedTableHead: React.FC<EnhancedTableHeadProps> = (props) => {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property: string) => (event: any) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead sx={{ bgcolor: "#132351" }}>
      <TableRow>
        <TableCell padding="checkbox">
          {/* <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
          /> */}
        </TableCell>
        {headCells.map((headCell: HeadCell) => (
          <TableCell
            sx={{ color: "#ffffff" }}
            key={headCell.id}
            // align={headCell.alignment}
            padding={headCell.disablePadding ? "none" : "normal"}
            // sortDirection={orderBy === headCell.id ? order : false}
          >
            {/* <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            > */}
            {headCell.label}
            {/* </TableSortLabel> */}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

function EnhancedTable({ rows }: any) {
  const [helpReq, setHelpReq] = useState<
    {
      requests: [];
      currentPage: 0;
      pages: 0;
      noOfHelpRequests: 0;
    }[]
  >(rows.requests);

  const [order, setOrder] = useState<"desc" | "asc">("asc");

  const [orderBy, setOrderBy] = useState("customer");

  const [page, setPage] = useState(0);

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [selected, setSelected] = useState<Array<string>>([]);

  const [loader, setLoader] = useState(false);

  const access_token = useSelector((state: any) => state.user.access_token);

  const navigate = useNavigate();

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n: any) => n.username);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (
    event: React.MouseEvent<HTMLTableCellElement, MouseEvent>,
    name: string
  ) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: Array<string> = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleRequestSort = (event: any, property: string) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = async (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    try {
      const { requests } = await GET(
        `${URL.GET_ALL_HELP_REQUEST}?pageNumber=${
          newPage + 1
        }&limit=${rowsPerPage}`,
        access_token
      );

      setHelpReq(requests);
    } catch (err) {
      console.log(err);
    } finally {
      setLoader(false);
    }
    setPage(newPage);
  };

  const handleChangeRowsPerPage = async (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    let limit = event.target.value;
    let current_page = 0;
    try {
      const { requests } = await GET(
        `${URL.GET_ALL_HELP_REQUEST}?pageNumber=${
          current_page + 1
        }&limit=${limit}`,
        access_token
      );

      setHelpReq(requests);
    } catch (err) {
      console.log(err);
    } finally {
      setLoader(false);
    }
    setRowsPerPage(parseInt(event.target.value, 10));
    current_page = 0;
    setPage(0);
    // setRowsPerPage(parseInt(event.target.value, 10));
    // setPage(0);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows?.length - page * rowsPerPage);

  useEffect(() => {
    setHelpReq(rows.requests);
  }, [rows.requests]);

  const formatDate = (createdAt: string) => {
    const date = new Date(createdAt);

    // Format date as "DD-MM-YYYY"
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  };

  const formatTime = (createdAt: string) => {
    const date = new Date(createdAt);

    // Format time as "hh:mmAM/PM"
    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const amPm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12; // Convert 0 to 12 for 12-hour format

    return `${hours}:${minutes}${amPm}`;
  };
  return (
    <div>
      <Paper>
        {!rows ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "50vh",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Paper>
            {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
            <TableContainer>
              <Table
                aria-labelledby="tableTitle"
                size={"medium"}
                aria-label="enhanced table"
              >
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                />
                <TableBody>
                  {/* {stableSort(rows ? rows : [], getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) */}
                  {helpReq?.map((row: any, index: number) => {
                    const isItemSelected = isSelected(row.username);

                    return (
                      <TableRow
                        hover
                        // role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row._id}
                        selected={isItemSelected}
                      >
                        <TableCell
                          padding="checkbox"
                          onClick={(event) => handleClick(event, row.username)}
                        >
                          {/* <Checkbox
                              checked={isItemSelected}
                              inputProps={{ "aria-labelledby": labelId }}
                            /> */}
                        </TableCell>
                        <TableCell align="left">{row.username}</TableCell>
                        <TableCell align="left">{row.email}</TableCell>
                        <TableCell align="left">{row.message}</TableCell>
                        <TableCell align="left">
                          {formatDate(row.createdAt)}
                        </TableCell>
                        <TableCell align="left">
                          {formatTime(row.createdAt)}
                        </TableCell>
                        <TableCell align="right">
                          <ReplyIcon
                            sx={{ cursor: "pointer" }}
                            onClick={() => {
                              navigate(`/support/reply/${row._id}`);
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={8} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={rows.noOfHelpRequests || 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        )}
      </Paper>
    </div>
  );
}

function HelpDesk() {
  const [rows, setRows] = useState<any>({
    requests: [],
    currentPage: 0,
    pages: 0,
    noOfHelpRequests: 0,
  });
  const access_token = useSelector((state: any) => state.user.access_token);

  const getRows = async () => {
    const { requests, currentPage, pages, noOfHelpRequests } = await GET(
      `${URL.GET_ALL_HELP_REQUEST}?pageNumber=${1}&limit=${10}`,
      access_token
    );

    setRows({
      requests,
      currentPage,
      pages,
      noOfHelpRequests,
    });
  };

  useEffect(() => {
    getRows();
  }, []);

  return (
    <React.Fragment>
      <Helmet title="Support" />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            HelpDesk
          </Typography>
        </Grid>
      </Grid>

      <Box my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <EnhancedTable rows={rows ? rows : null} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
export default HelpDesk;

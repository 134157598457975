import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { URL } from "../../../services/API";
import { GET, POST, PUT } from "../../../services/API/AxiosRequests";
import { NotificationStack } from "../../../services/utils/RenderToast";

export default function ViewTableInfo({
  open,
  setOpen,
  individualUser,
  setIndividualUser,
}: any) {
  const [loader, setLoader] = useState(false);

  const [plan, setPlan] = useState("");

  const access_token = useSelector((state: any) => state.user.access_token);

  const retrieveSubscription = async () => {
    setLoader(true);

    if (individualUser && individualUser?.subscriptionID) {
      try {
        const response = await GET(
          URL.GET_CURRENT_STRIPE_PLAN(individualUser?.subscriptionID)
        );
        const plan = response.product.name;

        setPlan(plan);

        setLoader(false);
      } catch (error) {
        setLoader(false);
        console.log(error);
      }
    } else {
      setPlan("free");
      setLoader(false);
    }
  };

  const getDate = (date: any) => {
    const inputDate = new Date(date);

    const formattedDate = inputDate.toLocaleDateString("en-US", {
      day: "numeric",
      month: "numeric",
      year: "numeric",
    });

    return formattedDate;
  };

  useEffect(() => {
    // if (!individualUser) return;

    retrieveSubscription();
  }, [open]);

  return (
    <Paper>
      <Dialog
        open={open}
        onClose={() => {
          setIndividualUser({
            createdAt: "",
            email: "",
            name: "",
            stripeCustomerID: "",
            subscriptionID: "",
            __v: -1,
            _id: "",
          });
          setOpen(false);
        }}
      >
        <Box sx={{ width: ["100%", "600px"] }}>
          {loader ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "33vh",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <>
              <Typography variant="h3" textAlign={"center"} sx={{ mt: 5 }}>
                User Info
              </Typography>
              <Box
                sx={{
                  p: 5,
                  paddingX: "30px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography variant="h4">Subscribed Plan : </Typography>
                <Typography sx={{ ml: 3 }} fontSize={15}>
                  {plan ?? "No Plan Purchased"}
                </Typography>
              </Box>
              <Box
                sx={{
                  p: 5,
                  paddingX: "30px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography variant="h4">Available Credit : </Typography>
                <Typography sx={{ ml: 3 }} fontSize={15}>
                  {String(
                    individualUser?.wordsLimit - individualUser?.wordsCount ?? 0
                  )}
                </Typography>
              </Box>
              <Box
                sx={{
                  p: 5,
                  paddingX: "30px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography variant="h4">Bonus Credit : </Typography>
                <Typography sx={{ ml: 3 }} fontSize={15}>
                  {individualUser?.addons ?? 0}
                </Typography>
              </Box>
              <Box
                sx={{
                  p: 5,
                  paddingX: "30px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography variant="h4">Sign-up Date : </Typography>
                <Typography sx={{ ml: 3 }} fontSize={15}>
                  {getDate(individualUser?.createdAt) ?? "N/A"}
                </Typography>
              </Box>

              <Box
                sx={{
                  p: 5,
                  paddingX: "30px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography variant="h4">Phone Number : </Typography>
                <Typography sx={{ ml: 3 }} fontSize={15}>
                  {individualUser?.phoneNo ?? "N/A"}
                </Typography>
              </Box>

              <Box
                sx={{
                  p: 5,
                  paddingX: "30px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography variant="h4">Subscription Count : </Typography>
                <Typography sx={{ ml: 3 }} fontSize={15}>
                  {individualUser?.subscriptionCount ?? "N/A"}
                </Typography>
              </Box>
              {individualUser?.subscriptionStatus === "canceled" && (
                <Box>
                  <Typography
                    sx={{ fontSize: "22px", pl: "30px", fontWeight: 600 }}
                  >
                    User Feedback While Cancelling Subscription :
                  </Typography>
                  <Box
                    sx={{
                      p: 5,
                      paddingX: "30px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="h4">Comments : </Typography>
                    <Typography sx={{ ml: 3 }} fontSize={15}>
                      {individualUser?.userSubscriptionComment === ""
                        ? "No comments passed."
                        : individualUser?.userSubscriptionComment}
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      p: 5,
                      paddingX: "30px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="h4">Feedback : </Typography>
                    <Typography sx={{ ml: 3 }} fontSize={15}>
                      {individualUser?.userSubscriptionFeedback === ""
                        ? "No feedback given."
                        : individualUser?.userSubscriptionFeedback}
                    </Typography>
                  </Box>
                </Box>
              )}

              <DialogActions sx={{ mb: "10px", mr: "15px" }}>
                <Button
                  type="button"
                  color="primary"
                  variant="outlined"
                  onClick={() => {
                    setIndividualUser({
                      createdAt: "",
                      email: "",
                      name: "",
                      stripeCustomerID: "",
                      subscriptionID: "",
                      __v: -1,
                      _id: "",
                    });
                    setOpen(false);
                  }}
                >
                  Cancel
                </Button>
              </DialogActions>
            </>
          )}
        </Box>
      </Dialog>
    </Paper>
  );
}

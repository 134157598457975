import styled from "@emotion/styled";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { NavLink } from "react-router-dom";

import {
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import { URL } from "../services/API";
import { GET } from "../services/API/AxiosRequests";

import EnhancedTemplateTable from "./EnhancedTemplateTable";
import { useSelector } from "react-redux";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

function ViewTable() {
  const [rows, setRows] = useState<any>({
    templates: [],
    currentPage: 0,
    pages: 0,
    noOfTemplates: 0,
  });

  const [open, setOpen] = useState(false);

  const [loading, setLoading] = useState(true);

  const [deletionId, setDeletionId] = useState<string>();

  const access_token = useSelector((state: any) => state.user.access_token);

  const getRows = async () => {
    try {
      const { templates, currentPage, pages, noOfTemplates } = await GET(
        `${URL.GET_TEMPLATES}?pageNumber=${1}&limit=${10}`,
        access_token
      );

      setRows({
        templates,
        currentPage,
        pages,
        noOfTemplates,
      });
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getRows();
  }, [open, setOpen, deletionId]);

  return (
    <React.Fragment>
      <Helmet title="View" />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            View
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/dashboard">
              Template
            </Link>

            <Typography>view</Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>

      <Box my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <EnhancedTemplateTable
            rows={rows ? rows : null}
            setLoading={setLoading}
            setDeletionId={setDeletionId}
            deletionId={deletionId}
            setOpen={setOpen}
            open={open}
            loading={loading}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
export default ViewTable;

import { createSlice } from "@reduxjs/toolkit";

export interface IAdmin {
  user: User;
  access_token: string;
}

export interface User {
  _id: string;
  name: string;
  email: string;
  isAdmin: boolean;
  createdAt: string;
  stripeCustomerID: string;
  subscriptionID: string;
}

const initialState: IAdmin = {
  access_token: "",
  user: {
    _id: "",
    name: "",
    email: "",
    isAdmin: false,
    createdAt: "",
    stripeCustomerID: "",
    subscriptionID: "",
  },
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.access_token = action.payload.access_token;
      state.user = action.payload.user;
    },

    removeUser: (state) => {
      state.access_token = "";
      state.user = {
        _id: "",
        name: "",
        email: "",
        isAdmin: false,
        createdAt: "",
        stripeCustomerID: "",
        subscriptionID: "",
      };
    },
  },
});

export const { setUser, removeUser } = userSlice.actions;
export default userSlice.reducer;
